<template>
  <div class="app-conten">
    <!-- 左侧导航 -->
    <div class="leftNav" v-if="$route.query.isSubStyle == 'LEFT'">
      <div
        :class="
          $route.query.columnTheme == 'DEFAULT' ? 'lefthead' : 'leftheadchange'
        "
      >
        {{ $store.state.dynamicParentName.columnsName }}
      </div>
      <div class="parents">
        <div
          class="mutios"
          :class="{
            classStyle:
              $store.state.statusTypes == 0
                ? $store.state.secondLevel.id === obj.id &&
                  $route.query.columnTheme == 'DEFAULT'
                : index === countent,
            classStylechange:
              $store.state.statusTypes == 0
                ? $store.state.secondLevel.id === obj.id &&
                  $route.query.columnTheme == 'RED'
                : index === countent,
          }"
          @click="navRightFn(index, newsList, obj)"
          v-for="(obj, index) in newsList"
          :key="obj.id"
        >
          <span> {{ obj.columnsName }}</span>
          <i class="iconfont icon-xiayibu"></i>
        </div>
      </div>
    </div>
    <!-- 单体 -->
    <div style="width: 100%">
      <!-- 当前位置 -->
      <div
        class="head_title"
        v-if="$route.query.isSubStyle == 'LEFT'"
        :class="
          $route.query.columnTheme == 'DEFAULT'
            ? 'head_title'
            : 'head_titleCHANGE'
        "
      >
        <img
          width="1.25rem"
          height="1.25rem"
          src="../../../assets//allImage//presscenter/icon_home@2x.png"
          alt=""
        />
        <div>当前位置：</div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item style="color: #999999"
            ><router-link :to="{ path: '/' }"
              >首页</router-link
            ></el-breadcrumb-item
          >
          <el-breadcrumb-item>{{
            $store.state.dynamicParentName.columnsName
          }}</el-breadcrumb-item>
          <el-breadcrumb-item v-show="$store.state.secondLevel.columnsName">{{
            $store.state.statusTypes == 0
              ? $store.state.secondLevel.columnsName
              : currentNickname
          }}</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- <div v-for="obj in currentPosition" :key="obj.id">
            {{ obj.title }}
          </div> -->
      </div>

      <!-- 内容 -->
      <div v-if="companyList.length == 0" style="width: 100%">
        <div class="content_box" v-for="(item, index) in newList" :key="index">
          <h2>{{ item.title }}</h2>
          <div class="paramContent" v-html="item.content"></div>
        </div>
      </div>
      <div v-else style="width: 100%">
        <div
          class="content_box"
          v-for="(item, index) in companyList"
          :key="index"
        >
          <h2 v-if="item.columnsName">{{ item.columnsName }}</h2>
          <h2 v-else>{{ item.title }}</h2>
          <div class="content_item" v-if="isDatePageviews == '0'">
            <div>
              时间：<span style="color: #c1c1c1">{{ item.updateTime }}</span>
            </div>
            <div style="margin-left: 20px">
              浏览量：<span style="color: #c1c1c1">{{
                item.viewsNumbers
              }}</span>
            </div>
            <div style="margin-left: 20px" v-if="item.source">
              信息来源：<span style="color: #c1c1c1">{{ item.source }}</span>
            </div>
          </div>
          <!-- <div class="linehor"></div> -->
          <div class="paramContent" v-html="item.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      isDatePageviews: '',
      parentList: [],
      queryParams: {
        pageNum: 1, //当前页数
        pageSize: 10, //每页10条
        columnId: '',
      },
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapState(['companyList']),
    ...mapState(['newList']),
    newsList() {
      return (this.parentList = this.$store.state.commonNavigationList);
    },
  },
  // 监听路由传来的值，只要变化了就要重新赋值
  watch: {
    '$route.query': {
      immediate: true,
      handler(newVal, oldVal) {
        this.isDatePageviews = newVal.isDatePageviews;
      },
    },
  },
  methods: {
    // 路由跳转
    async navRightFn(index, newsList, obj) {
      this.contents = index;
      this.$router.push({
        path: '/layout/companyProfile',
        query: {
          id: obj.id,
          columnPageType: obj.columnPageType,
          columnPageStyle: obj.columnPageStyle,
          isRegular: obj.isRegular,
          paramID: obj.parentId,
          isSubStyle: obj.isSubStyle,
          columnTheme: obj.columnTheme,
          isDatePageviews: obj.isDatePageviews,
        },
      });

      this.queryParams.columnId = obj.id;
      this.$store.state.secondLevel = obj;
      this.$store.state.commonNavigationList = newsList;
      this.countent = index;
      this.$store.state.secondLevel.id = obj.id;
      // 修改状态
      this.$store.commit('changeState', 0);
      if (obj.isRegular == 2) {
        this.$store.dispatch('companyDepartmentSetting');
      } else {
        // 公司概况
        this.$store.dispatch('companyProfileList', obj.id);
        // 新闻列表
        this.$store.dispatch('newsListFn', this.queryParams);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.app-conten {
  width: 100%;
  display: flex;
  /* flex-direction: column; */

  padding: 2.5rem 11.9rem;
  .leftNav {
    width: 23.75rem;
    background-color: #fff;
    border-radius: 0.25rem;
    margin-right: 0.9375rem;
    .lefthead,
    .leftheadchange {
      width: 100%;
      // height: 3.4375rem;
      background-color: #0aa4ee;
      font-size: 1.125rem;
      line-height: 3.4375rem;
      color: #fff;
      border-radius: 0.25rem 0.25rem 0 0;
      font-weight: 600;
    }
    .leftheadchange {
      background-color: #c50c11;
    }
    .parents {
      width: 100%;
      padding: 0 0.9375rem;
      .mutios {
        width: 100%;
        height: 2.1875rem;
        line-height: 2.1875rem;
        text-align: left;
        position: relative;
        padding: 0 0.625rem;
        margin-top: 0.625rem;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        color: black;
        cursor: pointer;
        .icsStyle {
          color: #c1c1c1;
        }
        .icon-xiayibu {
          color: #c1c1c1;
        }
        // &::before {
        //   content: '';
        //   width: 0.3125rem;
        //   height: 1.875rem;
        //   background-color: #0aa4ee;
        //   position: absolute;
        //   top: 0.0625rem;
        //   left: 0;
        // }
      }
      .classStyle,
      .classStylechange {
        width: 100%;
        height: 2.1875rem;
        line-height: 2.1875rem;
        text-align: left;
        position: relative;
        padding: 0 0.625rem;
        margin-top: 0.625rem;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        color: #0aa4ee;

        .icon-xiayibu {
          color: #0aa4ee;
        }
        &::before {
          content: '';
          width: 0.3125rem;
          height: 1.875rem;
          background-color: #0aa4ee;
          position: absolute;
          top: 0.0625rem;
          left: -0.9375rem;
        }
      }
      .classStylechange {
        color: #c50c11;
        .icon-xiayibu {
          color: #c50c11;
        }
        &::before {
          content: '';
          width: 0.3125rem;
          height: 1.875rem;
          background-color: #c50c11;
          position: absolute;
          top: 0.0625rem;
          left: -0.9375rem;
        }
      }
    }
  }
  .head_title,
  .head_titleCHANGE {
    width: 100%;
    height: 3.4375rem;
    display: flex;
    align-items: center;
    border-bottom: 0.0625rem solid #0aa4ee;
    // background-color: yellow;
    font-size: 0.875rem;
    padding: 0 0.625rem;
    color: #999999;
    margin-bottom: 1.25rem;

    img {
      margin-right: 0.3125rem;
    }
  }
  .head_titleCHANGE {
    border-bottom: 0.0625rem solid #c50c11;
  }
  .content_box {
    h2 {
      margin-bottom: 1.875rem;
      align-items: center;
    }
    .my_columnsName {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 1.875rem;
    }
    .paramContent {
      text-indent: 2em;
      text-align: left;
      line-height: 2.1875rem;
      ::v-deep img {
        max-width: 90% !important;
      }
    }
    .content_item {
      width: 100%;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }
    .linehor {
      width: 100%;
      height: 1px;
      background-color: black;
      margin-top: 10px;
    }
  }
}
</style>

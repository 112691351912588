<template>
  <div class="app">
    <el-carousel height="37.5rem" v-if="carouselList.length > 0">
      <el-carousel-item v-for="item in carouselList" :key="item.id">
        <img :src="imgPic + item.fileUri" width="100%" height="600px" alt="" />
      </el-carousel-item>
    </el-carousel>

    <el-carousel height="37.5rem" v-else>
      <el-carousel-item v-for="(obj, index) in defalutList" :key="index">
        <img :src="obj.fileUri" width="100%" height="600px" alt="" />
      </el-carousel-item>
    </el-carousel>
    <!-- 公共导航 -->
    <commonNavigation
      v-if="$route.query.isSubStyle == 'TOP' || $route.query.isSubStyle == ''"
    />
    <!--列表页 -->
    <div class="app-content" v-if="$route.query.columnPageType == 'LIST'">
      <cephalicBlock></cephalicBlock>
    </div>
    <!-- 单体页 -->
    <div
      v-if="
        $route.query.columnPageType == 'MONOMER' ||
        $route.query.columnPageStyle == ''
      "
    >
      <Intro></Intro>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import commonNavigation from '../../layout/components/commonNavigation';
// 单体页
// import Intro from '../../../views/CompanyProfile/Intro/index';
import Intro from '../../views/CompanyProfile/Intro/index.vue';
// 列表页
import cephalicBlock from '../newsModule/cephalicBlock';
import { slideshowList, newsListpAGE } from '@/api/index';

export default {
  components: {
    Intro,
    commonNavigation,
    cephalicBlock,
  },

  data() {
    return {
      carouselList: [], //公共轮播图
      // moren: require('../../assets/allImage/defaultCarouselChart/toutu-fuwuwangdain@1x.png'),
      myURL: '',
      defalutList: [
        {
          fileUri: require('../../assets/allImage/defaultCarouselChart/gongsigaikuang@1x.png'),
        },
      ],
      id: 0,
      imgUrl: '',
      // fullscreenLoading: false, //加载效果
      pageStatus: '',
      total: 0,
      queryParams: {
        pageNum: 1, //当前页数
        pageSize: 10, //每页10条
        columnId: '',
      },
      imgPic: '',
      columnId: '',
      id: 0,
      NewList: [],
      countent: 0,
      currentPage4: 4,
      imageSize: 'cover',
      paramID: '',
      argument: {
        id: '',
        columnId: '',
      },
    };
  },

  computed: {
    ...mapState(['overallQueryParams']),
    ...mapState(['commColumnId']),
    ...mapState(['wholeTotal']),
    ...mapState(['departmentList']),
    ...mapState(['newList']),
    ...mapState(['fullscreenLoading']),
    // ...mapState(['carouselList']),
  },
  created() {
    this.pageStatus = this.$store.state.pageIdentity;
    this.imgPic = process.env.VUE_APP_BASE_URL;
  },
  mounted() {
    this.myURL = process.env.VUE_APP_BASE_URL;
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newId, oldId) {
        this.id = newId.id;
        this.rotationChart(newId);
      },
    },
  },
  methods: {
    // 公共轮播图
    async rotationChart(newId) {
      this.carouselList = [];
      const res = await slideshowList(newId.id);
      if (res.data.code == 200) {
        if (res.data.data && res.data.data.length > 0) {
          this.carouselList = res.data.data;
        }
      } else {
        this.carouselList = [];
        // 如果二级没有图片，那就默认一级导航的图片
        const res = await slideshowList(newId.paramID);
        if (res.data.code == 200) {
          if (res.data.data && res.data.data.length > 0) {
            this.carouselList = res.data.data;
          }
        }
      }
    },

    getDetalis(item, index) {
      // 获取轮播图
      if (!this.$store.state.secondLevel.columnsName) {
        this.paramID = this.$route.query.id;
      } else {
        this.paramID = this.$route.query.paramID;
      }
      // 轮播图
      this.$store.dispatch('publicRotationChart', this.paramID);
      if (this.$route.query.isRegular == '2') {
        this.$store.dispatch('companyDeails', item.id);
      } else {
        // 新闻详情的值
        this.argument = {
          id: item.id,
          columnId: item.columnId,
        };
        // 文章详情
        this.$store.dispatch('companyArticleDetails', this.argument);
      }
      this.$router.push({
        path: '/layout/companyProfile/contentDetails',
        query: {
          id: item.id,
          currentIndex: index,
          isRegular: this.$route.query.isRegular,
        },
      });
    },
    navFn(index) {
      this.countent = index;
    },
    // 一页显示几条
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.queryParams.pageNum = 1;
      this.queryParams.columnId = this.commColumnId;
      this.$store.commit('globalpagingPageNum', this.queryParams);
      this.$store.dispatch('newsListFn', this.overallQueryParams);
    },
    handleCurrentChange(val) {
      this.queryParams.pageSize = 10;
      this.queryParams.pageNum = val;
      this.queryParams.columnId = this.commColumnId;
      this.$store.commit('globalpagingPageNum', this.queryParams);
      // this.queryParams.pageNum = val;
      // this.queryParams.columnId = this.commColumnId;
      this.$store.dispatch('newsListFn', this.overallQueryParams);
    },
  },

  // watch: {
  //   $route(to) {
  //     this.id = to.query.id;
  //   },
  // },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.app {
  width: 100%;
  background-color: #f5f5f5;
  .subnavigation {
    width: 80%;
    height: 2.1875rem;
    margin: 0 auto;
    padding: 0 5rem;
    display: flex;
    align-items: center;
    .moduleNavigation {
      padding: 0.3125rem 0.625rem;
      border: 0.0625rem #c50c11 solid;
      border-radius: 0.25rem;
      margin-right: 0.9375rem;
      color: #c50c11;
      margin-top: 0.625rem;
      cursor: pointer;
    }
    .navst {
      padding: 0.3125rem 0.625rem;
      background-color: #c50c11;
      border-radius: 0.25rem;
      margin-right: 0.9375rem;
      color: #fff;
      margin-top: 0.625rem;
      cursor: pointer;
    }
  }
  .app-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .item_son {
      width: 80%;
      padding: 0 5rem;
      display: flex;
      flex-wrap: wrap;
      margin: 1.875rem;
      .obj_box {
        width: 37.5rem;
        height: 7.5rem;
        border-radius: 0.5rem;
        padding: 1.25rem 1.25rem 0 1.25rem;
        margin: 0.625rem;
        background-color: #fff;
        display: flex;
        // justify-content: space-between;
        position: relative;
        .my_pic {
          width: 5rem;
          height: 5rem;
          margin-right: 0.9375rem;
          margin-left: 0.625rem;
          img {
            width: 100%;
            height: 100%;
            border-radius: 0.25rem;
          }
        }
        .tow_Laout {
          flex: 1;
          .title_sk {
            height: 2.5rem;
            // width: 31.25rem;
            text-align: left;
            text-overflow: ellipsis;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            cursor: pointer;
          }

          .content_sk {
            height: 2.1875rem;
            margin-top: 0.625rem;
            font-size: 0.75rem;
            line-height: 2.1875rem;
            border-top: 0.0625rem dashed #b9b7b7;
            display: flex;
            justify-content: space-between;
            .vd_ds {
              color: #0aa4ee;
              cursor: pointer;
            }
          }
        }
        .srcImg {
          width: 1.25rem;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
  .pagination_block {
    width: 80%;
    height: 3.125rem;
    margin: 0 auto;
    text-align: right;
    padding-right: 5rem;
  }
}
.app-content {
  width: 100%;
  .msgPic {
    width: 100%;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-conten"},[(_vm.$route.query.isSubStyle == 'LEFT')?_c('div',{staticClass:"leftNav"},[_c('div',{class:_vm.$route.query.columnTheme == 'DEFAULT' ? 'lefthead' : 'leftheadchange'},[_vm._v(" "+_vm._s(_vm.$store.state.dynamicParentName.columnsName)+" ")]),_c('div',{staticClass:"parents"},_vm._l((_vm.newsList),function(obj,index){return _c('div',{key:obj.id,staticClass:"mutios",class:{
          classStyle:
            _vm.$store.state.statusTypes == 0
              ? _vm.$store.state.secondLevel.id === obj.id &&
                _vm.$route.query.columnTheme == 'DEFAULT'
              : index === _vm.countent,
          classStylechange:
            _vm.$store.state.statusTypes == 0
              ? _vm.$store.state.secondLevel.id === obj.id &&
                _vm.$route.query.columnTheme == 'RED'
              : index === _vm.countent,
        },on:{"click":function($event){return _vm.navRightFn(index, _vm.newsList, obj)}}},[_c('span',[_vm._v(" "+_vm._s(obj.columnsName))]),_c('i',{staticClass:"iconfont icon-xiayibu"})])}),0)]):_vm._e(),_c('div',{staticStyle:{"width":"100%"}},[(_vm.$route.query.isSubStyle == 'LEFT')?_c('div',{staticClass:"head_title",class:_vm.$route.query.columnTheme == 'DEFAULT'
          ? 'head_title'
          : 'head_titleCHANGE'},[_c('img',{attrs:{"width":"1.25rem","height":"1.25rem","src":require("../../../assets//allImage//presscenter/icon_home@2x.png"),"alt":""}}),_c('div',[_vm._v("当前位置：")]),_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{staticStyle:{"color":"#999999"}},[_c('router-link',{attrs:{"to":{ path: '/' }}},[_vm._v("首页")])],1),_c('el-breadcrumb-item',[_vm._v(_vm._s(_vm.$store.state.dynamicParentName.columnsName))]),_c('el-breadcrumb-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.secondLevel.columnsName),expression:"$store.state.secondLevel.columnsName"}]},[_vm._v(_vm._s(_vm.$store.state.statusTypes == 0 ? _vm.$store.state.secondLevel.columnsName : _vm.currentNickname))])],1)],1):_vm._e(),(_vm.companyList.length == 0)?_c('div',{staticStyle:{"width":"100%"}},_vm._l((_vm.newList),function(item,index){return _c('div',{key:index,staticClass:"content_box"},[_c('h2',[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"paramContent",domProps:{"innerHTML":_vm._s(item.content)}})])}),0):_c('div',{staticStyle:{"width":"100%"}},_vm._l((_vm.companyList),function(item,index){return _c('div',{key:index,staticClass:"content_box"},[(item.columnsName)?_c('h2',[_vm._v(_vm._s(item.columnsName))]):_c('h2',[_vm._v(_vm._s(item.title))]),(_vm.isDatePageviews == '0')?_c('div',{staticClass:"content_item"},[_c('div',[_vm._v(" 时间："),_c('span',{staticStyle:{"color":"#c1c1c1"}},[_vm._v(_vm._s(item.updateTime))])]),_c('div',{staticStyle:{"margin-left":"20px"}},[_vm._v(" 浏览量："),_c('span',{staticStyle:{"color":"#c1c1c1"}},[_vm._v(_vm._s(item.viewsNumbers))])]),(item.source)?_c('div',{staticStyle:{"margin-left":"20px"}},[_vm._v(" 信息来源："),_c('span',{staticStyle:{"color":"#c1c1c1"}},[_vm._v(_vm._s(item.source))])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"paramContent",domProps:{"innerHTML":_vm._s(item.content)}})])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }